import React, { useEffect } from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  const isMobile = window.matchMedia('(max-width: 1000px)').matches;

  useEffect(() => {
    const el = document.getElementById('App');
    if (el) {
      el.style.minHeight = window.innerHeight + 'px';
    }
  }, []);

  return (
    <div className="App" id="App">
      <img src={logo} className="App-logo" alt="logo" />
      <div className="Main-links">
        <a
          className="App-link"
          href="https://bonco.choiceqr.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          меню десертів
        </a>
        <a
          className="App-link"
          href="https://t.me/bonco_kyiv"
          target="_blank"
          rel="noopener noreferrer"
        >
          telegram
        </a>
        <a
          className="App-link"
          href="https://www.instagram.com/bonco.kyiv/"
          target="_blank"
          rel="noopener noreferrer"
        >
          instagram
        </a>
      </div>
      <div className="Footer-links">
        <a
          className="App-bottom-link"
          href="https://goo.gl/maps/fHzFMLAbRnr1gb2e9"
          target="_blank"
          rel="noopener noreferrer"
        >
          БУЛЬВАР ВАЦЛАВА ГАВЕЛА, 9А
        </a>
        {isMobile ? (
          <a
            className="App-bottom-link Phone-number"
            href="tel:+380962490430"
            target="_blank"
            rel="noopener noreferrer"
          >
            096 249 04 30
          </a>
        ) : (
          <span>096 249 04 30</span>
        )}
      </div>
    </div>
  );
}

export default App;
